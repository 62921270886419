import type { Campaign } from '@ramdam/campaigns';
import type { ViewCollaboration } from '@ramdam/collaborations';
import type { ServerDrivenUIComponent } from '@ramdam/server-driven-ui';

import type { TierKey } from '~/features/tiers/types/tier';
import { safeAbsurd } from '~/utils/safeAbsurd';

import type { User } from '../../../users/types';
import type { TrackingData, TrackingItem } from '../../core/types/trackingItem';

export type AppEvents = 'signup-start' | 'signup-success' | 'signup-error';
export type CollaborationEvents = 'send-state-success' | 'submit-success';

type CollaborationEvent =
  | {
      type: 'collaboration-event';
      name: 'send-state-success' | 'submit-success';
      data: {
        collaboration: ViewCollaboration;
        component?: ServerDrivenUIComponent;
      } & TrackingData;
    }
  | {
      type: 'collaboration-event';
      name: 'rehire-drawer-closed';
      data: {
        collaborationId: string;
      };
    }
  | {
      type: 'collaboration-event';
      name: 'rehire-drawer-opened';
      data: {
        collaborationId: string;
      };
    }
  | {
      type: 'collaboration-event';
      name: 'rehire-step-changed';
      data: {
        collaborationId: string;
        toStep: string;
      };
    }
  | {
      type: 'collaboration-event';
      name: 'rehire-completed';
      data: {
        collaborationId: string;
      };
    };

type CampaignEvents = {
  type: 'campaign-event';
  name: 'publish';
  data: {
    campaignId: string;
    campaignName: string;
    language: Campaign['targeting']['language'];
    countries: Campaign['targeting']['countries'];
    campaignNetworks: Campaign['targeting']['campaign_networks'];
    ageMin: Campaign['targeting']['age_min'];
    ageMax: Campaign['targeting']['age_max'];
    genders: Campaign['targeting']['genders'];
    specialRequirements: string | undefined;
    hasCustomSpecialRequirements: boolean;
    recommendedTier?: TierKey;
    recommendedTierId: string;
    selectedTier: TierKey;
    selectedTierId: string;
    selectedTierPrice: number;
    selectedTierCurrency: string;
    selectedTierApplicationsPredictionCount?: number;
    selectedTierReachRate?: number;
    selectedTierBudgetEfficiency?: string;
    isBudgetRecommentationFollowed: boolean;
  };
};
export type AppTrackingItem =
  | { type: 'event'; name: AppEvents; data?: TrackingData }
  | CollaborationEvent
  | {
      type: 'collaborations-event';
      name: CollaborationEvents;
      data: { collaborationIds: ViewCollaboration['id'][]; component?: ServerDrivenUIComponent } & TrackingData;
    }
  | {
      type: 'mainUser';
      user: User;
      organization?: {
        name: string;
        id: string;
      };
    }
  | { type: 'pageView'; path: string; name?: string }
  | CampaignEvents;

export function convertAppTrackingItemToTrackingItem(appTrackingItem: AppTrackingItem): TrackingItem {
  switch (appTrackingItem.type) {
    case 'collaboration-event':
      return {
        type: 'event',
        name: `collaboration.${appTrackingItem.name}`,
        data: (() => {
          const name = appTrackingItem.name;
          switch (name) {
            case 'send-state-success':
            case 'submit-success':
              return {
                ...getCollaborationTrackingData(appTrackingItem.data.collaboration),
                ...(appTrackingItem.data.component
                  ? getServerDrivenComponentTrackingData(appTrackingItem.data.component)
                  : {}),
              };
            case 'rehire-drawer-opened':
            case 'rehire-completed':
            case 'rehire-drawer-closed':
              return {
                collaboration_id: appTrackingItem.data.collaborationId,
              };
            case 'rehire-step-changed':
              return {
                collaboration_id: appTrackingItem.data.collaborationId,
                to_step: appTrackingItem.data.toStep,
              };
            default:
              return safeAbsurd(name, {}, 'invalid_app_tracking_collaborations_item_name');
          }
        })(),
      };
    case 'collaborations-event':
      return {
        type: 'event',
        name: `collaborations.${appTrackingItem.name}`,
        data: {
          ...getCollaborationsTrackingData(appTrackingItem.data.collaborationIds),
          ...(appTrackingItem.data.component
            ? getServerDrivenComponentTrackingData(appTrackingItem.data.component)
            : {}),
        },
      };
    case 'mainUser':
      if (appTrackingItem.organization) {
        return {
          type: 'identify',
          user: appTrackingItem.user,
          organization: {
            name: appTrackingItem.organization.name,
            id: appTrackingItem.organization.id,
          },
        };
      } else {
        return {
          type: 'identify',
          user: appTrackingItem.user,
        };
      }

    case 'campaign-event':
      return {
        type: 'event',
        name: `campaign.${appTrackingItem.name}`,
        data: {
          campaign_id: appTrackingItem.data.campaignId,
          campaign_name: appTrackingItem.data.campaignName,
          language: appTrackingItem.data.language,
          countries: appTrackingItem.data.countries,
          campaign_networks: appTrackingItem.data.campaignNetworks,
          age_min: appTrackingItem.data.ageMin,
          age_max: appTrackingItem.data.ageMax,
          genders: appTrackingItem.data.genders,
          special_requirements: appTrackingItem.data.specialRequirements,
          has_custom_special_requirements: appTrackingItem.data.hasCustomSpecialRequirements,
          recommended_tier: appTrackingItem.data.recommendedTier,
          recommended_tier_id: appTrackingItem.data.recommendedTierId,
          selected_tier: appTrackingItem.data.selectedTier,
          selected_tier_id: appTrackingItem.data.selectedTierId,
          selected_tier_price: appTrackingItem.data.selectedTierPrice,
          selected_tier_currency: appTrackingItem.data.selectedTierCurrency,
          selected_tier_applications_prediction_count: appTrackingItem.data.selectedTierApplicationsPredictionCount,
          selected_tier_reach_rate: appTrackingItem.data.selectedTierReachRate,
          selected_tier_budget_efficiency: appTrackingItem.data.selectedTierBudgetEfficiency,
          is_budget_recommentation_followed: appTrackingItem.data.isBudgetRecommentationFollowed,
        },
      };

    default:
      return appTrackingItem;
  }
}

function getCollaborationTrackingData(collaboration: ViewCollaboration): TrackingData {
  return {
    collaboration_id: collaboration.id,
    collaboration_campaign_id: collaboration.campaignId,
    creator_id: collaboration.creator.id,
    collaboration_state_name: collaboration.state.name,
    collaboration_state_metadata: collaboration.deliverable,
  };
}

function getCollaborationsTrackingData(collaborationIds: ViewCollaboration['id'][]): TrackingData {
  return {
    collaborations_id: collaborationIds.join(','),
  };
}

function getServerDrivenComponentTrackingData(component: ServerDrivenUIComponent): TrackingData {
  switch (component.type) {
    case 'button':
      return {
        action: component.action,
        action_title: component.title,
      };
    default:
      return {};
  }
}
